import { FC, useCallback, useEffect } from "react";
import logo from "./logo.png";
import Android from "./Android.png";
import Apple from "./Apple.png";
import "./App.css";
import { isAndroid, isIOS } from "react-device-detect";

const App: FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const checkApps = useCallback(async () => {
    const isBeta = urlParams.get("beta");

    if (isBeta) {
      window.alert("Beta enabled");
      const listOfInstalledApps = await (
        navigator as any
      ).getInstalledRelatedApps();
      for (const app of listOfInstalledApps) {
        window.alert("app" + JSON.stringify(app));
      }
    }

    let redirectUri = window.location.pathname;
    const resetPwdParam = new URLSearchParams(window.location.search).get(
      "ResetPassword"
    );
    let redirectUriApp = "ongocargas://";
    if (redirectUri !== "/") {
      if (redirectUri.startsWith("/")) {
        redirectUri = redirectUri.substring(1);
      }
      redirectUriApp += redirectUri;
    }

    if (resetPwdParam) {
      redirectUriApp += `ResetPassword=${resetPwdParam}`;
    }

    window.open(redirectUriApp);

    setTimeout(() => {
      window.close();
      if (isAndroid) {
        window.open(
          `https://play.google.com/store/apps/details?id=br.com.ongo.cargas`
        );
      } else if (isIOS) {
        window.open(`https://apps.apple.com/br/app/on-go-cargas/id1371497312`);
      }
    }, 1500);
  }, [navigator, urlParams]);

  useEffect(() => {
    checkApps();
  }, [urlParams]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="DownloadApp">
          {(isAndroid || (!isAndroid && !isIOS)) && (
            <a href="https://play.google.com/store/apps/details?id=br.com.ongo.cargas">
              <img src={Android} alt="Android loja" />
            </a>
          )}
          {(isIOS || (!isAndroid && !isIOS)) && (
            <a href="https://apps.apple.com/br/app/on-go-cargas/id1371497312">
              <img src={Apple} alt="IOS Loja" />
            </a>
          )}
        </div>
      </header>
    </div>
  );
};

export default App;
